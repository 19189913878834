$(document).ready(function () {
    $("#grain-search").autocomplete({
        source: function(request, response) {
            $.ajax({
                url: "/autocomplete/grain_search",
                dataType: "json",
                data: {
                    term: request.term
                },
                success: function(data) {
                    if (data.length === 0) {
                        $("#grain-search").css("color", "red");
                    } else {
                        $("#grain-search").css("color", "black");
                    }
                    response(data);
                }
            });
        },
        minLength: 2,
        select: function(event, ui) {
            // Redirect to the specific URL
            window.location.href = "/nexus/products/" + ui.item.id;
        }
    });

    $("#grain-search").on("input", function() {
        if ($(this).val().length > 0) {
            $(this).css("color", "black");
        }
    });

    $(".linkable").click(function(){
        // window.location = $(this).attr("data-href");
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });

    $(".linkable-news-feed-item").click(function(){
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });

    $(".linkable-org").click(function(){
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });

    $(".linkable-nexus-item").click(function(){
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });
});